/* additional styles for alert content. Used by both public side and dashboard */

.alert {
  .alertinner {
    position:relative;
    &.wicon {
      padding-left:0.5em;
      margin-left:0.5em;
    }
    [class*="icon-"]{
      position:absolute;
      top:0.3em;
      right:100%;
    }
    p {
      margin:10px 0 0 0;
      &:first-child {
          margin:0;
      }
    }
  }
}
