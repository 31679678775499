// Modifications / additons to wells
.well {
	.clearfix();
}
.well-success {
	background-color: @state-success-bg;
	border-color: @state-success-border;
}
.well-info {
	background-color: @state-info-bg;
	border-color: @state-info-border;
}
.well-blank {
	background-color: @white;
	border-color: rgba(0,0,0,.1);
}