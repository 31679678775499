// Re setting margins to the bottom only
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
.control-group,
.table,
.page-header,
.sub-header {
    margin:0 0 @line-height-computed;
}
ul,
ol {
    margin-bottom:@line-height-computed;
}
.page-header,
.sub-header,
.table {
    h1, h2, h3, h4, h5, h6, form {
        margin-bottom:0;
    }
}

// Heading fonts a little smaller
h1, .h1 { font-size: @font-size-base * 2.15; line-height: 1.3; } // 30px
h2, .h2 { font-size: @font-size-base * 1.75; line-height: 1.3; } // 24px
h3, .h3 { font-size: @font-size-base * 1.25; line-height: 1.4; } // 18px
h4, .h4 { font-size: @font-size-base * 1.15; line-height: 1.4; } // 16px
h5, .h5 { font-size: @font-size-base; line-height: @line-height-base; } // 14px

.h1,
.h2,
.h3 {
    margin-top: 0;
    margin-bottom: 0;
}

// Page / sub headers consistant
.sub-header {
    padding-bottom: (@line-height-computed / 2) - 1;
    border-bottom: 1px solid @gray-lighter;
}
.page-header {
    h1,h2 {
        font-size: @font-size-base * 2.14;
        line-height: 40px;
    }
}
// Sub header a little lighter
.sub-header {
    h2,h3,h4 {
        font-size: @font-size-base * 1.6; // 22px
        line-height: @line-height-base * 1.5;
        font-weight:normal;
        color:@gray-dark;
    }
}

// Text alignment
.align-right,
.table .align-right,
.table .align-right {
    text-align:right;
}

.align-center,
.table .align-center,
.table .align-center {
    text-align:center;
}
