// OSCAR PLUGINS STYLES
// -----------


// Carousel overrides, idicators in the center bottom
.carousel {
  margin-bottom: @line-height-computed * 2;
}
.carousel-indicators {
  top: auto;
  right: auto;
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: center;
  li {
    display: inline-block;
    *display: inline;
    *text-indent: 0;
    zoom: 1;
    float: none;
    background-color: #ccc;
    background-color: rgba(0,0,0,.5);
    cursor: pointer;
    text-align: center;

  }
  .active {
    background-color: #000;
  }
}
// Center the carousel image
.carousel-inner > .item > img, 
.carousel-inner > .item > a > img {
	margin: 0 auto;
}

// Multiple image gallery carousel over-rides
#product_gallery {
  .carousel-inner > .item {
    height: 400px;
    line-height: 400px;
    vertical-align: middle;
    text-align: center;
  }
  .carousel-inner > .item > img {
    display: inline-block;
    vertical-align: middle;
    max-height: 400px;
  }
  .carousel-control {
    background-image: none;
  }
  .carousel-indicators {
    position: static;
    bottom: 0;
    margin: 0;
    width: auto;
    display: block;
    .clearfix();
    li {
      width: 17%;
      height: auto;
      display: block;
      float: left;
      margin: @line-height-computed/4;
      text-indent: 0;
      background-color: #fff;
      .opacity(50);
    }
    .active {
      .opacity(100);
    }
  }
  .thumbnail {
    .transition(none);
    position: relative;
  }
}
