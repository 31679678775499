/* BOOTSTRAP SPECIFIC CLASSES
 * -------------------------- */

/* keeps Bootstrap styles with and without icons the same */
.btn, .nav {
  [class^="icon-"],
  [class*=" icon-"] {
//    display: inline;
    &.icon-large { line-height: .9em; }
    &.icon-spin { display: inline-block; }
  }
}
.nav-tabs, .nav-pills {
  [class^="icon-"],
  [class*=" icon-"] {
    &, &.icon-large { line-height: .9em; }
  }
}
.btn {
  [class^="icon-"],
  [class*=" icon-"] {
    &.pull-left, &.pull-right {
      &.icon-2x { margin-top: .18em; }
    }
    &.icon-spin.icon-large { line-height: .8em; }
  }
}
.btn.btn-small {
  [class^="icon-"],
  [class*=" icon-"] {
    &.pull-left, &.pull-right {
      &.icon-2x { margin-top: .25em; }
    }
  }
}
.btn.btn-large {
  [class^="icon-"],
  [class*=" icon-"] {
    margin-top: 0; // overrides bootstrap default
    &.pull-left, &.pull-right {
      &.icon-2x { margin-top: .05em; }
    }
    &.pull-left.icon-2x { margin-right: .2em; }
    &.pull-right.icon-2x { margin-left: .2em; }
  }
}
