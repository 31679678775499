// Review styles
.review {
    margin-bottom:@line-height-computed;
    .clearfix();
    h3 {
        margin-bottom: @line-height-computed / 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    // Vote forms inline with "Is this review helpful?"
    form {
        display:inline-block;
        *display:inline;
        zoom:1;
        margin-bottom:0;
    }
}

// Star Rating using font icons for 5 stars
.star-rating {
    color:#CBCBCB;
    i {
	letter-spacing: -1px;
	width:auto;
    }
    a {
        margin-left:10px;
    }
}
.star-rating.One {
    i:first-child {
        color:#E6CE31;
    }
}
.star-rating.Two {
    i:first-child, i:first-child + i {
        color:#E6CE31;
    }
}
.star-rating.Three {
    i:first-child, i:first-child + i, i:first-child + i + i {
        color:#E6CE31;
    }
}
.star-rating.Four {
    i:first-child, i:first-child + i, i:first-child + i + i, i:first-child + i + i + i {
        color:#E6CE31;
    }
}
.star-rating.Five {
    i {
        color:#E6CE31;
    }
}