// Oscar FORMS

form {
    .clearfix();
    &.flat {
        margin:0;
    }
}

// For IE: adds width for selects inside overflow hidden containers
.select-open {
    width:300px!important;
}

// Additional errors
.errorlist {
    margin:0;
    padding:0 0 10px 0;
    color:#B94A48;
    li {
        padding:0 0 5px 0;
        list-style:none;
    }
}

.error-block {
    color: #b94a48;
    margin-top: 6px;
}

.control-label.required:after {
    color: #FF0000;
    content:"*";
    display: inline-block;
}

/* weird alternative to btn-block for specific use on the product page, where btn-block would cause a vertical misalignment when product size options are shown */
.btn-add-to-basket {
    width:100%;
}

/* star rating functionality for product review page */
.reviewrating {
    select {
        display:none;
    }
    .star-rating {
        margin-bottom:10px;
        font-size:280%;
        i {
            cursor:pointer;
        }
    }
}

.form-actions {
    margin: @line-height-computed 0;
    padding: @line-height-computed;
    background-color: @form-actions-bg-color;
    border-top: solid 1px @form-actions-border-color;
}
