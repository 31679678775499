// OSCAR PRODUCTS UNIQUE STYLES
// -----------
.availability {
    &.outofstock {
        color:@brand-danger;
    }
    &.instock {
        color:@brand-success;
    }
}

// PRICE SIZE/COLOR
// -----------
.price_color {
    display:block;
    color:@brand-success;
    font-weight:bold;
}

.product_main .price_color {font-size: 26px; }
.product_pod .price_color { margin-top:0 }
.es-carousel .price_color { font-size:20px; }

// Added Well mixin to page elements
// TODO: good to have this as this it is easy to remove/update rather than a
// class in the template. But maybe better moved from here.
.review_add,
.review_all,
.review_read,
.side_categories {
    .well();
    li {
        ul,
        ol {
            padding-left: 25/14em;
        }
    }
}

// Wish lists
// Align wish list button with add to basket button
.add-to-basket {
    //Width of default input/select/textarea
    width: 220px;
    margin-bottom:0;
    margin-right:10px;
    *margin-right:20px;
}
.add-to-basket,
.btn-wishlist {
    display:inline-block;
    *display:inline;
    zoom:1;
}
// For responsive - when button falls under each other
.btn-wishlist {
    vertical-align:bottom;
}
.add-to-basket .btn,
.btn-wishlist {
    margin-bottom:@line-height-computed;
}

#add_review_form {
    #id_title, #id_body {
        width: 500px;
    }
}
