// OSCAR PAGE STRUCTURE
// -----------

body {
    background-color: @gray-lighter;
}

.container-fluid {
    // Here is the page width: editable in page/mixins.less
    max-width: @oscarpagewidth;
}

.row {
    .box-sizing(border-box);
}

// The page container
.page .page_inner {
    background:@white;
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.15);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.15);
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
}

// Some padding for the inner container
// Also useful for mobile
.page_inner {
    padding:@grid-gutter-width;
    .clearfix();
}
