// Promotional block styles
.promotion_single {
    .image_container {
        @imagecontainerheight: 210px;
        min-height: @imagecontainerheight;
        margin-bottom: @line-height-computed;
        img {
            max-height: @imagecontainerheight - 10;
        }
    }
}
.sidebar {
    .promotion_single {
        h2 {
            .h3();
        }
        h3 {
            .h5();
        }
        .row {
            > [class*="col-"] {
                float:none;
                width:auto;
            }
        }
    }
}